// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artist-calligraphy-js": () => import("./../../../src/pages/artist/calligraphy.js" /* webpackChunkName: "component---src-pages-artist-calligraphy-js" */),
  "component---src-pages-artist-conceptart-js": () => import("./../../../src/pages/artist/conceptart.js" /* webpackChunkName: "component---src-pages-artist-conceptart-js" */),
  "component---src-pages-artist-dotworks-js": () => import("./../../../src/pages/artist/dotworks.js" /* webpackChunkName: "component---src-pages-artist-dotworks-js" */),
  "component---src-pages-artist-js": () => import("./../../../src/pages/artist.js" /* webpackChunkName: "component---src-pages-artist-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-design-js": () => import("./../../../src/pages/industrial-design.js" /* webpackChunkName: "component---src-pages-industrial-design-js" */),
  "component---src-pages-projects-cookplay-rex-collection-js": () => import("./../../../src/pages/projects/cookplay-rex-collection.js" /* webpackChunkName: "component---src-pages-projects-cookplay-rex-collection-js" */),
  "component---src-pages-projects-cookplay-tears-collection-js": () => import("./../../../src/pages/projects/cookplay-tears-collection.js" /* webpackChunkName: "component---src-pages-projects-cookplay-tears-collection-js" */),
  "component---src-pages-projects-fightech-underglove-js": () => import("./../../../src/pages/projects/fightech-underglove.js" /* webpackChunkName: "component---src-pages-projects-fightech-underglove-js" */),
  "component---src-pages-projects-futureair-sam-20-sensor-js": () => import("./../../../src/pages/projects/futureair-sam20-sensor.js" /* webpackChunkName: "component---src-pages-projects-futureair-sam-20-sensor-js" */),
  "component---src-pages-projects-ghostprint-organic-grips-js": () => import("./../../../src/pages/projects/ghostprint-organic-grips.js" /* webpackChunkName: "component---src-pages-projects-ghostprint-organic-grips-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-kef-inear-mu-3-js": () => import("./../../../src/pages/projects/kef-inear-mu3.js" /* webpackChunkName: "component---src-pages-projects-kef-inear-mu-3-js" */),
  "component---src-pages-projects-mameha-facedry-brush-js": () => import("./../../../src/pages/projects/mameha-facedry-brush.js" /* webpackChunkName: "component---src-pages-projects-mameha-facedry-brush-js" */),
  "component---src-pages-projects-mameha-oilwallet-dispenser-js": () => import("./../../../src/pages/projects/mameha-oilwallet-dispenser.js" /* webpackChunkName: "component---src-pages-projects-mameha-oilwallet-dispenser-js" */),
  "component---src-pages-projects-natuzzi-ergo-collection-js": () => import("./../../../src/pages/projects/natuzzi-ergo-collection.js" /* webpackChunkName: "component---src-pages-projects-natuzzi-ergo-collection-js" */),
  "component---src-pages-projects-prometeo-perfusion-machine-js": () => import("./../../../src/pages/projects/prometeo-perfusion-machine.js" /* webpackChunkName: "component---src-pages-projects-prometeo-perfusion-machine-js" */),
  "component---src-pages-projects-puzhen-aromadiffuser-js": () => import("./../../../src/pages/projects/puzhen-aromadiffuser.js" /* webpackChunkName: "component---src-pages-projects-puzhen-aromadiffuser-js" */),
  "component---src-pages-projects-smart-robots-controlhead-js": () => import("./../../../src/pages/projects/smart-robots-controlhead.js" /* webpackChunkName: "component---src-pages-projects-smart-robots-controlhead-js" */),
  "component---src-pages-projects-yape-land-drone-js": () => import("./../../../src/pages/projects/yape-land-drone.js" /* webpackChunkName: "component---src-pages-projects-yape-land-drone-js" */)
}

