import "./src/styles/global.css";
import "./node_modules/slick-carousel/slick/slick.css"; 
import "./node_modules/slick-carousel/slick/slick-theme.css";
import React from "react"
import GlobalContextProvider from "./src/context/GlobalContextProvider"

export const wrapRootElement = ({ element }) => {
  console.log('Made by Alessio Premoli - https://github.com/alessiopremoli')
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}
