export const INDUSTRIAL_LAYOUT = 'industrialLayout'
export const ARTIST_LAUYOUT = 'artistLayout'

export const projectNames = {
    KEF_MU3: 'KEF_MU3',
    MAMEHA_WALLET: 'MAMEHA_WALLET',
    MAMHEA_BRUSH: 'MAMHEA_BRUSH',
    FUTUREAIR_SAM: 'FUTUREAIR_SAM',
    PUZHEN_AROMA_DIFFUSER: 'PUZHEN_AROMA_DIFFUSER',
    NATUZZI: 'NATUZZI',
    YAPE: 'YAPE',
    SMART_ROBOTS: 'SMART_ROBOTS',
    FIGTECH: 'FIGTECH',
    PROMETEO: 'PROMETEO',
    GHOSTPRINT: 'GHOSTPRINT_ORGANIC_GRIPS',
    COOKPLAY: 'COOKPLAY_REX_COLLECTION',
    COOKPLAY_TEARS: 'COOKPLAY_TEARS_COLLECTION',
}

export const pojectInfos = {
    COOKPLAY_TEARS_COLLECTION: {
        client: "COOKPLAY",
        year: "2023",
        description: "Tears collection has born from the desire of Cookplay to present a further aspiration in the tableware world. The redefining of Jomon, their first iconic collection after 10 years wants to express and intensify the “tactile interaction” for a rich experience at the table. The design, created by Ana Roquero, the CEO and Design Director of Cookplay, invites you to a new culinary approach, its unique texture language will shake the design and composition of the high end gastronomy. My activity in this case has been focused on 3d modelling Ana Roquero's Designs, making them ready for production.",
        hashtags: ["cookplay", "tabletopmatters", "tableware", "tabledecor", "tablewaredesign", "fooddesign"]
    },
    COOKPLAY_REX_COLLECTION: {
        client: "COOKPLAY",
        year: "2022",
        description: "Rex collection has born from the desire of Cookplay to turn texture into the subject of their products, giving new life and new meaning to the rappresentation. The design, created by Ana Roquero, the CEO and Design Director of Cookplay, wants to interact with the food that will be served, creating a new binomial, where the eye of the user do not see two separeted pieces, but single entity. My activity in this case has been focused on 3d modelling Ana Roquero's Designs, making them ready for production.",
        hashtags: ["cookplay", "tabletopmatters", "tableware", "tabledecor", "tablewaredesign", "fooddesign"]
    },
    KEF_MU3: {
        client: "KEF",
        year: "2019-2020",
        studio: "Ross Lovegrove Studio",
        description: "In these high fidelity wireless inear headphones with noise canceling, the shape becomes an aesthetic and physical guide for the blind use of the product. Physicality thus becomes a function, but it does not forget its artistic being, expressed in a case with elegant and primordial lines. The user interface is minimal: a single LED, hidden under the surface of the product, becomes a single interface for the user, thus allowing the form itself to become a new means of communication, no longer visual but tactile.",
        hashtags: ["KEF", "audio", "inear", "noisecancelling", "earphones"]
    },
    MAMEHA_WALLET: {
        client: "MAMEHA",
        year: "2019-2021",
        studio: "Ross Lovegrove Studio",
        description: "Mameha is a skincare startup firm with the aim of creating a new daily based ritual able to cure, protect, clean and improve everyone skin. To achieve this, the company decided to design a new experience based on 100% reusable flasks cointaining a different essential oil for each day of the week and a resin based 3d printed ecosystem of containers and wallets to keep the flasks ordered and protected while they are carried inside a bag or a purse.",
        hashtags: ['mameha ', 'skincare ', 'health ', 'essentialoil ', '3dprinting']
    },
    MAMHEA_BRUSH: {
        client: "MAMEHA",
        year: "2019-2021",
        studio: "Ross Lovegrove Studio",
        description: "In addition to the kit of essential serums, the company has decided to propose a further innovation in the field of dry brushing: thanks to the use of 3d printing, a new device has in fact been created with a high number of customized follicles, designed to massage the skin and awaken by toning the skin of the face. The device remains 100% biocompatible, although made of resin, thanks to the use of a protective stocking that covers the follicles, improving the massage process.",
        hashtags: ['mameha', 'skincare', 'drybrush', 'facecare', '3dprinting'],
    },
    FUTUREAIR_SAM: {
        client: "FUTUREAIR",
        year: "2019-2021",
        studio: "Ross Lovegrove Studio",
        description: "SAM™ monitors, manages and improves the air we breathe indoors. It collects air quality data to monitor for toxins 24/7 and initiate actions with connected devices to improve indoor air quality, thermal comfort and energy efficiency. Could be mounted to a wall or placed on a surface, becoming a beacon of clean air in any room. SAM™ is designed to communicate through an backlit color system that indicates changes in a room’s air quality and a live dashboard on mobile app.",
        hashtags: ['futureair', 'pollution', 'health', 'indoor', 'beacon', 'IOT'],
    },
    PUZHEN_AROMA_DIFFUSER: {
        client: "PUZHEN",
        year: "2019-2021",
        studio: "Ross Lovegrove Studio",
        description: "Puzhen, Chinese leader in the field of room diffusers, requested the development of a series of concepts for a portable aroma diffuser, whose task was to refresh and hydrate the user’s face, as well as converting into a portable respirator with the addition of healthy herbaceous essences. The concepts developed have as an aesthetic constraint formal cleanliness and ease of use, essential for a product for daily use, while maintaining the Lovegrove stylistic language.",
        hashtags: ['Puzhen', 'Aromadiffuser', 'portable', 'clean', 'applelike'],
    },
    NATUZZI: {
        client: "NATUZZI",
        year: "2019-2021",
        studio: "Ross Lovegrove Studio",
        description: "Ergo collection, made by a bed, a chaise long, a valet, a ceiling lamp, a chest of drawers and a floor lamp is an organic concept inspired “by the earth for the earth”. The wood used comes from certified plantations, its components interlock, so that each piece can be put together without any metallic parts. The adhesives used are all formaldehyde-free and water-based. All of the finishes are natural wax. The textile fibres used for all of the upholstery are linen, wool and cotton.",
        hashtags: ['natuzzi', 'ergo', 'bedroom', 'naturalmaterial', 'puglia'],
        awards: {
            adi: false,
            compasso: false,
            german: false,
            reddot: true,
            dyson: false,
        }
    },
    YAPE: {
        client: "YAPE",
        year: "2016-2019",
        studio: "E-Novia s.p.a.",
        description: "Yape is an autonomous self-balancing vehicle for home delivery designed for the most demanding urban environments. Moving on the lane dedicated to bikes or on the sidewalk, it is able to deliver goods and food at a fraction of the cost and without personal responsibility for injuries, producing zero emissions. Its main field of use is the city center and metropolitan area where today more and more traffic is restricted to lorries.",
        hashtags: ['yape', 'urbandelivery', 'electric', 'autonomous', 'drone'],
        awards: {
            adi: true,
            compasso: false,
            german: true,
            reddot: false,
            dyson: false,
        }
    },
    SMART_ROBOTS: {
        client: "SMART ROBOTS",
        year: "2017-2019",
        studio: "E-Novia s.p.a.",
        description: "Smart Robots is a device that can best manage an environment in which human operators and robots work side by side. It allows you to map space in real time, recognize objects, movements and gestures of people, interact with them by adapting the robots it is connected to. A solution that, in a single object of light weight and weight, can be positioned on an easel or a wall, combines ability of perception and reasoning.",
        hashtags: ['smartrobot', 'safe', 'protection', 'ai', 'human', 'cooperation'],
    },
    FIGTECH: {
        client: "SWAT Fighting Club",
        year: "2017-2018",
        studio: "E-Novia s.p.a.",
        description: "The Fightec project represents the development of a first prototype of sensorized underglove for combat sports able to assess the strength and speed of each fist and adjust its trajectory, thanks to customized sensors placed on the knuckles and a dedicated app. This glove is also able to replace traditional bandages promising complete protection of the hand and thus becoming the perfect training partner of every fighter.",
        hashtags: ['fightec', 'fighting', 'cutman', 'training', 'safe', 'MMA', 'boxe',],
    },
    PROMETEO: {
        client: "POLIMI",
        year: "2015-2016",
        studio: "-",
        description: "Prometeo is a device for hypothermic oxygenated continuous perfusion dedicated to transportation of liver for transplantation. Born as a synthesis between the idea of transportability typical of organ containers, and the extracorporeal perfusion technology, able to keep an organ out of the body alive, this product lengthens the time needed for transport, reduces the number of organs donated and lost, increases the pool of potential donors.",
        hashtags: ['prometeo', 'liver', 'perfusion', 'surgery', 'transplant', 'donor',],
        awards: {
            adi: true,
            compasso: true,
            german: false,
            reddot: false,
            dyson: true,
        }
    },
    GHOSTPRINT_ORGANIC_GRIPS: {
        client: "Ghostprint - Stratagen3D Canada",
        year: "2021-2022",
        description: "With this project the exploration of ergonomics mixed with formfindings techniques and pattern studies is stretched to its extremes. The aim is to create the lightest, highest performing Airsoft parts ever developed, designed and produced with 3d printing technology. Thanks to this approach, the handles are designed to be hollow, covered with small but still tough lattices able to sustain the effort of an hard game. Every part we make is focused on making you a better Player. Every extra ounce is removed, creating grips and stocks that are tough and lightweight.",
        hashtags: ['ghostprint', 'softair', '3dprinting', 'lightweight', 'game'],
    }
}

export const MY_LINKS = {
    LINKEDIN: 'https://www.linkedin.com/in/gabriele-faoro-22524026/',
    INSTAGRAM: 'https://www.instagram.com/gaab.id.studio/',
    UPWORK: 'https://www.upwork.com/o/profiles/users/~01ab68fb39d71e712c/',
    CV: 'https://www.dropbox.com/s/5jtlpc5mqahad48/Gabriele%20Faoro%20CV%20EnglishVersion%202022.pdf?dl=1'
}

export const bioEntries = [
    {
        who: "Freelancing Activity",
        what: "Industrial Designer and Artist",
        where: "Worldwide",
        when: "Jun. 2021 - Today"
    },
    {
        who: "Numen Institute",
        what: "3D Printing and Grasshopper Teacher",
        where: "Milan, ITALY",
        when: "Feb. 2021 - Today"
    },
    {
        who: "Claudio Bellini Design Studio",
        what: "Senior Industrial Designer",
        where: "Milan, ITALY",
        when: "Nov. 2020 - May 2021"
    },
    {
        who: "Ross Lovegrove LTD Design Studio",
        what: "Senior Industrial Designer",
        where: "London, UNITED KINGDOM",
        when: "Jan. 2019 - Nov. 2020"
    },
    {
        who: "E-Novia s.p.a.",
        what: "Senior Industrial Designer",
        where: "Milan, ITALY",
        when: "Feb. 2016 - Dec. 2018"
    },
]

export const bioEducation = [
    {
        who: "Master Sc. in Design & Engineering",
        what: "110L/110",
        where: "Polytechnic University of Milan",
        when: "Oct. 2013 - Dec. 2015"
    },
    {
        who: "Bachelor Degree in Industrial Design",
        what: "107/110",
        where: "Polytechnic University of Milan",
        when: "Oct. 2010 - Jul. 2013"
    },
]

export const videos = [
    {
        url: '/WebSite_3DShowcaseMu3_VideoQHD.mp4',
        title: 'MU3',
        pageUri: '/projects/kef-inear-mu3'
    },
    {
        url: '/WebSite_3DShowcaseCookplay_VideoQHD.mp4',
        title: 'REX COLLECTION',
        pageUri: '/projects/cookplay-rex-collection'
    },
    {
        url: '/WebSite_3DShowcaseGhostprint_VideoQHD.mp4',
        title: 'Ghostprint',
        pageUri: '/projects/ghostprint-organic-grips'
    },
    {
        url: '/WebSite_3DShowcaseYape_VideoQHD.mp4',
        title: 'Yape',
        pageUri: '/projects/yape-land-drone'
    },
    {
        url: '/WebSite_3DShowcasePuzhen_VideoQHD.mp4',
        title: 'AIR DIFFUSER',
        pageUri: '/projects/puzhen-aromadiffuser'
    },
    {
        url: '/WebSite_3DShowcaseMameha_VideoQHD.mp4',
        title: 'Mameha',
        pageUri: '/projects/mameha-oilwallet-dispenser'
    }
]