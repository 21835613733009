import React, { useReducer } from "react"
import { INDUSTRIAL_LAYOUT } from "./consts";

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
    theme: "industrialLayout",
}

function reducer(state, action) {
    switch (action.type) {
        case "TOGGLE_THEME": {
            document.documentElement.style.setProperty('--scrollbar-color', action.theme === INDUSTRIAL_LAYOUT ? 'var(--dark)' : 'var(--light');
            return {
                ...state,
                theme: action.theme ?? state.theme,
            }
        }
        default:
            throw new Error("Bad Action Type")
    }
}

const GlobalContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    return (
        <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    )
}

export default GlobalContextProvider
